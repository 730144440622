<template>
  <div class="main">
    <Modal
      v-model="isShowCancel"
      cancelText="确定取消"
      confirmText="暂不取消"
      title="提示"
      zIndex="100"
      @cancel="cancelOrder"
      @confirm="isShowCancel = false"
    >
      确定取消所有的订单吗？
    </Modal>
    <div class="top">
      <div class="top-num">{{ getTitle }}</div>
      <div v-if="successCount > 0" class="top-tips">请主动联系快递员确认上门时间</div>
    </div>
    <div class="detailmain">
      <div v-for="(item, index) in batchData" :key="index" class="detail">
        <div class="detail-content">
          <div class="detail-side">
            <div class="name">{{ item.sendName }}</div>
            <div>{{ item.sendCity }}</div>
          </div>
          <div class="detail-mid">
            <img src="https://cdn.kuaidi100.com/images/uniapp/order/detail/icon-plane.png" class="icon-plane" />
            <div class="text" :class="[item.success === 'Y' ? 'success' : 'fail']">下单{{ item.success === "Y" ? "成功" : "失败" }}</div>
          </div>
          <div class="detail-side">
            <div class="name">{{ item.recName }}</div>
            <div>{{ item.recCity }}</div>
          </div>
        </div>
        <div v-if="item.success === 'N' && item.failMsg" class="detail-fail">
          <!-- <image src="https://cdn.kuaidi100.com/images/better/alert.png" /> -->
          <span class="fail-msg">失败原因：{{ item.failMsg }}</span>
        </div>
      </div>
    </div>
    <div class="batch-btn">
      <button v-if="successCount > 0" @click.stop="isShowCancel = true">
        全部取消
      </button>
      <button @click.stop="goUrl">查看订单</button>
    </div>
  </div>
</template>
<script>
import Modal from "components/Modal/Modal"
export default {
  name: "batchSuccess",
  data() {
    return {
      batchData: [],
      batchNo: '',
      failCount: 0,
      successCount: 0,
      isShowCancel: false
    }
  },
  components: {
    Modal
  },
  computed: {
    getTitle() {
      if (this.successCount && this.failCount) {
        return `下单成功${this.successCount}单，下单失败${this.failCount}单`
      } else if (this.successCount) {
        return `下单成功${this.successCount}单`
      } else {
        return `下单失败${this.failCount}单`
      }
    }
  },
  created() {
    const batchResult = JSON.parse(localStorage.getItem('batchData')) || {}
    this.batchData = batchResult.data || []
    // localStorage.removeItem('batchData')
  },
  mounted() {
    // 访问 query 参数
    this.batchNo = this.$route.query.batchNo || 0
    this.failCount = parseInt(this.$route.query.failcount)
    this.successCount = parseInt(this.$route.query.successCount)
  },
  methods: {
    goUrl() {
      // uni.navigateTo({
      //   url: "/pages/mine/orders?tabId=0",
      // })
      this.$router.replace({
        name: "orderList"
      })
    },
    cancelOrder() {
      // 取消订单
      this.isShowCancel = false;
      this.cancelOrderBatch();
    },
    cancelOrderBatch() {
      // uni.navigateTo({
      //   url: `/pages/common/cancelOrder?batchNo=${this.batchNo}`,
      // })
      const order = { 
        batchNo: this.batchNo
      }
      this.navigator.push({
        name: "cancel",
        params: {
          event: "cancelOrderBatch",
          order: order
        }
      })
    }
  },

}
</script>

<style lang="scss" scoped>
@mixin flexLayout($justify: center, $align: center, $direction: row){
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin font($size, $color, $lineHeight, $weight: 400, $family: 'PingFangSC-Regular'){
  font-family: $family;
  font-style: normal;
  font-size: $size;
  color: $color;
  font-weight: $weight;
  line-height: $lineHeight;
}

@mixin overflowEllipsis(){
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin overflowMultiEllipsis($line: 2){
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}
.main {
  height: 100vh;
  overflow: auto;
  background: #efeff4;
}

.top {
  width: 95%;
  border-radius: .2857rem;
  background: #fff;
  margin: .7143rem auto 0;
  text-align: center;
  // height: 120rpx;
  padding: 2rem 0 2.5rem;
  .top-num {
    color: #333;
    font-size: 1.4286rem;
  }
  .top-tips {
    color: #ff7f02;
    font-size: 1.2143rem;
    margin-top: .9286rem;
  }
}

.detailmain {
  margin-bottom: 10.7143rem;
}

.detail {
  @include flexLayout($direction: column);
  width: 95%;
  border-radius: .2857rem;
  background: #fff;
  margin: .7143rem auto 0;
  padding-bottom: 1.0714rem;
  .detail-content {
    width: 100%;
    @include flexLayout;
  }
  .detail-fail {
    width: 98%;
    // height: 64rpx;
    padding: .5rem .7143rem;
    background: #FFEDED;
    border-radius: .1429rem;
    box-sizing: border-box;
    @include font(.8571rem, #FF4D4F, 1.2857rem);
    .fail-msg {
      @include overflowMultiEllipsis(3)
    }
  }
}
.detail-side {
  width: 30%;
  text-align: center;
  margin-top: 1.6429rem;
  margin-bottom: 1.5714rem;
  .name {
    @include overflowEllipsis;
  }
}

.detail-side div:first-child {
  font-size: 1.2143rem;
  color: #333;
}

.detail-side div:last-child {
  font-size: 1rem;
  color: #878787;
  margin-top: .5714rem;
}

.detail-mid {
  width: 40%;
  text-align: center;
  .icon-plane {
    width: 8.4286rem;
    height: 1.5rem;
  }
  .text {
    font-size: .8571rem;
  }
}

.detail-mid-desc {
  color: #317ee7;
  font-size: 1.0714rem;
  margin-top: .1429rem;
}

.success {
  color: #09bb07;
}

.fail {
  color: #FF4D4F;
}

.batch-btn {
  position: fixed;
  width: 100%;
  height: 4.5714rem;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fff;
}

.batch-btn button {
  width: 45%;
  height: 3.0714rem;
  border-radius: .2857rem;
  font-size: 1.2143rem;
  line-height: 3.0714rem;
  margin: 0 .7143rem;
  text-align: center;
}

.batch-btn button:first-child {
  border: 1px solid #c5c5c5;
  color: #333;
  background: #fff;
}

.batch-btn button:last-child {
  border: 1px solid #ff7f02;
  color: #fff;
  background: #ff7f02;
}
</style>
